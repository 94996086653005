import React from "react";
import "./cards.css";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";

// /imgs/Cargador.jpeg
// /imgs/Transporte.jpg
//  /imgs/Venta.jpg
function Cards() {
  return (
    <>
      <div style={{ padding: "0 1rem 0 1rem" }}>
        <Row className="MARGINTOPCLASS" justify={"space-between"}>
          <Col lg={5} md={5} sm={10} xs={10}>
            <Link to="/maquinaria">
              <div>
                <img
                  style={{
                    width: "100%",
                    border: "white solid 0.5rem",
                    borderRadius: "1rem",
                  }}
                  src="/imgs/Cargador.jpeg"
                />
              </div>
              <h4
                className="textcalssrentaequipo"
                style={{ textAlign: "center" }}
              >
                RENTA DE EQUIPO
              </h4>
              <h4
                className="textcalssrentaequipo"
                style={{ textAlign: "center" }}
              >
                Y MAQUINARIA PESADA
              </h4>
            </Link>
          </Col>
          <Col lg={5} md={5} sm={10} xs={10}>
            <Link to="/machinery-transportation">
              <div>
                <img
                  style={{
                    width: "100%",
                    border: "white solid 0.5rem",
                    borderRadius: "1rem",
                  }}
                  src="/imgs/Transporte.jpg"
                />
              </div>
              <h4
                className="textcalssrentaequipo"
                style={{ textAlign: "center" }}
              >
                TRANSPORTE DE MAQUINARIA
              </h4>
              <h4
                className="textcalssrentaequipo"
                style={{ textAlign: "center" }}
              >
                PESADA Y AGREGADOS PETREOS
              </h4>
            </Link>
          </Col>
          <Col lg={5} md={5} sm={10} xs={10}>
            <Link to="/venta">
              <div>
                <img
                  style={{
                    width: "100%",
                    border: "white solid 0.5rem",
                    borderRadius: "1rem",
                  }}
                  src="/imgs/Venta.jpg"
                />
              </div>
              <h4
                className="textcalssrentaequipo"
                style={{ textAlign: "center" }}
              >
                VENTA DE
              </h4>
              <h4
                className="textcalssrentaequipo"
                style={{ textAlign: "center" }}
              >
                AGREGADOS PETREOS
              </h4>
            </Link>
          </Col>
          <Col lg={5} md={5} sm={10} xs={10}>
            <Link to="/residuosPeligrosos">
              <div>
                <img
                  style={{
                    width: "96%",
                    // height: "25vh",
                    border: "white solid 0.5rem",
                    borderRadius: "1rem",
                  }}
                  src="/imgs/residue.jpeg"
                />
              </div>
              <h4
                className="textcalssrentaequipo"
                style={{ textAlign: "center" }}
              >
                RECOLECCIÓN Y TRANSPORTACIÓN
              </h4>
              <h4
                className="textcalssrentaequipo"
                style={{ textAlign: "center" }}
              >
                DE RESIDUOS PELIGROSOS Y RME
              </h4>
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Cards;

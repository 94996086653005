import React, { useState, useEffect } from "react";
import "./style.css";
import NavbarComponent from "../NavbarComponent";
import Footer from "../FooterComponent";
function Missiopn() {
  const [isVison, setisVison] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/vision") {
      setisVison(true);
    } else {
      setisVison(false);
    }
  }, [window.location.pathname]);

  return (
    <>
      {isVison && <NavbarComponent />}
      <div className="maindivofmission">
        <div className="firstchildmission">
          <h1 className="headingsmission">MISIÓN</h1>
          <h3 className="textofvission">
            Somos una empresa que proporciona soluciones integrales en proyectos
            de infraestructura, caminos y obras complementaria mediante la renta
            de maquinaria y equipos certificados; así como servicios
            especializados ejecutados con personal calificado que permiten
            generar un valor agregado a nuestros clientes, un retorno de
            inversión a nuestros accionistas, construyendo una relación sólida
            con nuestros proveedores y un sentido de pertenencia de nuestros
            colaboradores.
          </h3>
          <h1 className="headingsmission">VISIÓN</h1>
          <h3 className="textofvission">
            Consolidarnos en el sureste de México y ampliar nuestra presencia en
            la República Mexicana como una empresa que ofrece soluciones
            integrales a nuestros clientes, ofreciendo maquinaria y equipo de
            transporte especializado y servicios complementarios con altos
            estándares de calidad, innovando la prestación de los servicios
            conforme a los nuevos requerimientos del mercado, con una estructura
            organizacional eficiente y procesos ágiles.
          </h3>

          <h1 className="headingsmission">VALORES</h1>
          <h3 className="textofvission marginbottomclass">
            • Innovación: buscando siempre la forma de ofrecer diversas
            soluciones creativas a nuestros clientes.
          </h3>
          <h3 className="textofvission marginbottomclass">
            • Experiencia: ofrecemos nuestros servicios a empresas con
            reconocimiento internacional quienes nos confían sus proyectos.
          </h3>
          <h3 className="textofvission marginbottomclass">
            • Calidad: ofrecemos excelencia a nuestros clientes mediante la
            oferta de equipos certificados y personal calificado.
          </h3>
          <h3 className="textofvission marginbottomclass">
            • Excelencia técnica y altos estándares de calidad en todos nuestros
            servicios.
          </h3>
          <h3 className="textofvission marginbottomclass">
            • Solvencia: para dar capacidad de respuesta rápida y oportuna a los
            requerimientos de nuestros clientes.
          </h3>
          <h3 className="textofvission marginbottomclass">
            • Trabajamos en equipo en todo momento procurando un ambiente
            laboral de confort y confianza.
          </h3>
          <h3 className="textofvission marginbottomclass">
            • Comprometidos con la seguridad y salud de nuestro personal.
          </h3>
          <h3 className="textofvission marginbottomclass">
            • Cuidado del medio ambiente en la ejecución de nuestros procesos.
          </h3>
          <h3 className="textofvission marginbottomclass">
            • Comprometidos con la comunidad. Para mejorar el entorno social
          </h3>
        </div>
      </div>
      {isVison && <Footer />}
    </>
  );
}

export default Missiopn;

import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col } from "antd";
import Logo1919 from "../../image/Lowboy King (1).png";
import Logo1920 from "../../image/Lowboy Ramiro Petrolero (2).png";
import Logo19202 from "../../image/tchange.jpg";

import Logo2020 from "../../image/Lowboy Saxum Petrolero (3).png";
import Logo2121 from "../../image/tisapower.jpg";
import Footer from "../FooterComponent";
import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import { useSelector } from "react-redux";
import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";

function EighteenSection() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />
      <div>
        <div className="border">
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo1919} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KING</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">CAMA UTIL</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">7.6 M</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2.60 M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">CAPACIDAD DE CARGA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">20 T</h6>
                  </div>
                </div>

                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KING") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Low Boy",
                                  name: "KING",
                                  cama_util: "7.6 M",
                                  ancho: "2.60 M",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo1920} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">RAMIRO PETROLERO</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">CAMA UTIL</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">11.38 M</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO CUCHILLA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2.60M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">CAPACIDAD DE CARGA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">60 T</h6>
                  </div>
                </div>

                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("RAMIRO PETROLERO") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Low Boy",
                                  name: "RAMIRO PETROLERO",
                                  cama_util: "11.38 M",
                                  ancho: "2.60 M",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo2" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo2020} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">SAXUM PETROLERO</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">CAMA UTIL</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">12 M</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">3.05M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">CAPACIDAD DE CARGA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">60 T</h6>
                  </div>
                </div>

                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("SAXUM PETROLERO") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Low Boy",
                                  name: "SAXUM PETROLERO",
                                  cama_util: "12 M",
                                  ancho: "3.05M",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo19202} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">TSA ABATIBLE</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">CAMA UTIL</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">11.56 M</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">3.05 M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">CAPACIDAD DE CARGA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">60 T</h6>
                  </div>
                </div>

                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("TSA ABATIBLE") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Low Boy",
                                  name: "TSA ABATIBLE",
                                  cama_util: "11.56 M",
                                  ancho: "3.05M",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo2" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo2121} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">TISA PAVER</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">CAMA UTIL</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">10 M</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">3.05 M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">CAPACIDAD DE CARGA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">55 T</h6>
                  </div>
                </div>

                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("TISA PAVER") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Low Boy",
                                  name: "TISA PAVER",
                                  cama_util: "10 M",
                                  ancho: "3.05M",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EighteenSection;

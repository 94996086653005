import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col } from "antd";
//Petrolizadora Jabri
import Logo1313 from "../../image/Pavimentadora Bomag.jpg";
import Logo131 from "../../image/Pavimentadora Bomag F300.jpg";
import Logo13 from "../../image/Compactador Hidraulico Bomag BW24RH.png";
import Logo1 from "../../image/Compactador Mixto Bomag BW 161.png";
import Logo from "../../image/Petrolizadora Jabri.png";

import Footer from "../FooterComponent";
import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import { useSelector } from "react-redux";

import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";

function FourteenSection() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };
  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />
      <div>
        <div className="border">
          <h1
            style={{ marginTop: "2rem", color: "#214D80", fontWeight: "bold" }}
          >
            PAVIMENTADORAS
          </h1>
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo1313} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">BOMAG BF700C-3</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">21 TON</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO MÁXIMO</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">9 M3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">EXTENSIÓN HIDRAULICA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">2.5 - 5 M</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga" style={{ fontSize: "1.1rem" }}>
                      VELOCIDAD MÁXIMA OPERACIÓN
                    </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">25 M/MIN</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Ficha TEcnica Pavimentadora.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("BOMAG BF700C-3") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "PAVIMENTADORAS",
                                  name: "BOMAG BF700C-3",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo131} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">BOMAG BF300 CS340</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">8.5 TON</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO MÁXIMO</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">5 M3</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">EXTENSIÓN HIDRAULICA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">3.4 - 5 M</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga" style={{ fontSize: "1.1rem" }}>
                      VELOCIDAD MÁXIMA OPERACIÓN
                    </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">26 M/MIN</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Ficha TEcnica Pavimentadora.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("BOMAG BF300 CS340") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "PAVIMENTADORAS",
                                  name: "BOMAG BF300 CS340",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <h1
            style={{ marginTop: "2rem", color: "#214D80", fontWeight: "bold" }}
          >
            COMPACTADORES
          </h1>
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo13} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">HIDRAÚLICO BOMAG BW24RH</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">197 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO RODILLO</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2,130 MM</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">20,000 KG</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <a
                      href="https://www.bomag.com/dam/BOMAG/Other/Data_Sheets/Data_Sheets_E/Datasheet_BW24_27RH_PRE53800010_SA06.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("HIDRAÚLICO BOMAG BW24RH") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "COMPACTADORES",
                                  name: "HIDRAÚLICO BOMAG BW24RH",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img
                  className="bus img-fluid"
                  src={
                    "https://www.bomag.com/dam/BOMAG/Other/Old_Machines/123931557B_188367.jpg"
                  }
                  alt=""
                />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">TANDEM BOMAG WB161 AD-4</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">197 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO RODILLO</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2,130 MM</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">20,000 KG</h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Fica tecnica compactador tandem.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("TANDEM BOMAG WB161 AD-4") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "COMPACTADORES",
                                  name: "TANDEM BOMAG WB161 AD-4",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo1} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">MIXTO BOMAG BW 161 AC-5</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">11.5 TON</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">VEL. MAXIMA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">12 KM/H</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">20,000 KG</h6>
                  </div>
                </div>

                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">CAPACIDAD DE COMPACTACIÓN</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten" style={{ fontSize: "1rem" }}>
                      200 - 400 M<sup>3</sup>/H
                    </h6>
                  </div>
                </div>

                <div className="btonmng">
                  <div>
                    <a
                      href="https://www.bomag.com/dam/BOMAG/Other/Data_Sheets/Data_Sheets_E/Datasheet_BW151AC-5_BW161AC-5_PRE92116010_SA03.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("MIXTO BOMAG BW 161 AC-5") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "COMPACTADORES",
                                  name: "MIXTO BOMAG BW 161 AC-5",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <h1
            style={{ marginTop: "2rem", color: "#214D80", fontWeight: "bold" }}
          >
            BARREDORAS
          </h1>

          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img
                  className="bus img-fluid"
                  src={
                    "https://brocebroom.com/wp-content/uploads/2021/04/sweepers-broce-rjt350-11177256.jpg"
                  }
                  alt=""
                />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">BROCE BROOM RCT 350</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">5,500 LBS</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">VELOCIDAD DE TRABAJO</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">16 KM/H</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">VELOCIDAD DE TRANSPORTE</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">55 KM/H</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga" style={{ fontSize: "1.1rem" }}>
                      ANCHO DE BARRIDO
                    </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">8 PIES</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a href="/pdf/Barredora-350-1.pdf" target="_blank">
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("BROCE BROOM RCT 350") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "BARREDORAS",
                                  name: "BROCE BROOM RCT 350",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <h1
            style={{ marginTop: "2rem", color: "#214D80", fontWeight: "bold" }}
          >
            PETROLIZADORA
          </h1>
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">JABRI HIDRAULICA</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">TANQUE</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">10,000 LTS</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">BACHADOR/MANGUERA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">7M DE 1"</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">QUEMADORES</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">2</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga" style={{ fontSize: "1.1rem" }}>
                      ESPREAS
                    </h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">38</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/FICHA TECNICA PETROLIZADORA HIDRAULICA 10,000 LTS.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("JABRI HIDRAULICA") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "PETROLIZADORA",
                                  name: "JABRI HIDRAULICA",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FourteenSection;

import React from "react";
import "./style.css";
import Logo5 from "../../image/Retroexcavadora Komatsu PC210 Lc.jpg";
import Logo6 from "../../image/Pipa Agua Ford 10000 LT.png";
import Logo7 from "../../image/Gondolas.png";
import Logo8 from "../../image/Transporte Maquinaria Pesada.jpg";
import Logo9 from "../../image/Vehiculos Utilitarios Renta.jpg";
import Logo10 from "../../image/Freightliner DD 15.png";
import Logo11 from "../../image/Volteo Kenworth T480.jpg";
import Logo12 from "../../image/Gondolas renta.png";
import Logoken from "../../image/Kenworth Grua Articulada1.jpg";

import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import NavbarComponent from "../NavbarComponent";
import Footer from "../FooterComponent";

function FifthSection() {
  return (
    <>
      <NavbarComponent />
      <div className="body4">
        <Row justify="center">
          <Col lg={8} md={9} xs={24}>
            <div>
              <h3 className="tranporate">Equipos de Transporte</h3>
            </div>
            <div className="barhol">
              <Link to="/TRACTOCAMIONES">
                <div className="truckhol">
                  <div
                    style={{
                      width: "8.5rem",
                      height: "5rem",
                      marginTop: "-0.2rem",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        borderRadius: "1rem",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={Logo10}
                      alt=""
                    />
                  </div>
                  <div className="bar">
                    <h6 className="maqo">TRACTOCAMIONES</h6>
                  </div>
                </div>
              </Link>
              <Link to="/GONDOLAS">
                <div className="truckhol">
                  <div
                    style={{
                      width: "8.5rem",
                      height: "5rem",
                      marginTop: "-0.2rem",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        borderRadius: "1rem",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={Logo7}
                      alt=""
                    />
                  </div>
                  <div className="bar">
                    <h6 className="maqo">GONDOLAS</h6>
                  </div>
                </div>
              </Link>
              <Link to="/VOLTEOS">
                <div className="truckhol">
                  <div
                    style={{
                      width: "8.5rem",
                      height: "5rem",
                      marginTop: "-0.2rem",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        borderRadius: "1rem",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={Logo11}
                      alt=""
                    />
                  </div>
                  <div className="bar">
                    <h6 className="maqo">VOLTEOS</h6>
                  </div>
                </div>
              </Link>

              <Link to="/LOW-BOY">
                <div className="truckhol">
                  <div
                    style={{
                      width: "8.5rem",
                      height: "5rem",
                      marginTop: "-0.2rem",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        borderRadius: "1rem",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={Logo8}
                      alt=""
                    />
                  </div>
                  <div className="bar">
                    <h6 className="maqo">LOW BOY</h6>
                  </div>
                </div>
              </Link>
              <Link to="/VEHICULOS">
                <div className="truckhol">
                  <div
                    style={{
                      width: "8.5rem",
                      height: "5rem",
                      marginTop: "-0.2rem",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        borderRadius: "1rem",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={Logo9}
                      alt=""
                    />
                  </div>
                  <div className="bar">
                    <h6 style={{ marginTop: "-0.4rem" }} className="maqo">
                      VEHICULOS
                      <br />
                      UTILITARIOS
                    </h6>
                  </div>
                </div>
              </Link>
              <Link to="/PIPAS">
                <div className="truckhol">
                  <div
                    style={{
                      width: "8.5rem",
                      height: "5rem",
                      marginTop: "-0.2rem",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        borderRadius: "1rem",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={Logo6}
                      alt=""
                    />
                  </div>
                  <div className="bar">
                    <h6 className="maqo">PIPAS</h6>
                  </div>
                </div>
              </Link>
              <Link to="/GruaArticulada">
                <div className="truckhol">
                  <div
                    style={{
                      width: "8.5rem",
                      height: "5rem",
                      marginTop: "-0.2rem",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        borderRadius: "1rem",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={Logoken}
                      alt=""
                    />
                  </div>
                  <div className="bar">
                    <h6 className="maqo">GRUA ARTICULADA</h6>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col lg={12} md={12} xs={24}>
            <div className="truckpic">
              <img className="gondolas img-fluid" src={Logo12} alt="" />
            </div>
            <Row className="margin">
              <Col lg={6} md={6} xs={10} className="columofflex">
                <div>
                  <h5 className="flex">FLEXIBILIDAD</h5>
                  <p className="plazo">
                    En el plazo de ranta por dia, semana o mes.
                  </p>
                </div>
              </Col>
              <Col lg={6} md={6} xs={10} className="columofflex">
                <div>
                  <h5 className="flex">ENTREGA</h5>
                  <p className="plazo">
                    Entrega directa en la obra con descuentos por distancia.
                  </p>
                </div>
              </Col>
              <Col lg={6} md={6} xs={10} className="columofflex">
                <div>
                  <h5 className="flex">ASESORIA</h5>
                  <p className="plazo">
                    Asesoria tecnica relacionada con el manteni miento.
                  </p>
                </div>
              </Col>
              <Col lg={6} md={6} xs={10} className="columofflex">
                <div>
                  <h5 className="flex">
                    LAS MAJORES <br />
                    MARCAS
                  </h5>
                  <p className="plazo"> Respaldo de la marca del fabricante.</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default FifthSection;

import React from "react";
import "./style.css";
import { useState, useEffect } from "react";

import NavbarComponent from "../../components/NavbarComponent";
import Footer from "../../components/FooterComponent";
import FourthSection from "../../components/thirdSection/fourthSection/index";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function Cart() {
  const { width } = useWindowDimensions();

  return (
    <div>
      <NavbarComponent />
      <FourthSection />
      <div style={{ marginTop: "1rem" }}>
        <Footer width={width} />
      </div>
    </div>
  );
}

export default Cart;

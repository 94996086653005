import React, { useState, useEffect } from "react";
import Logo9999 from "../../image/TC-09-FREIGHTLINER-CASCADIA-2017.png";
import { Col, Row } from "antd";
import { updateProductsState } from "../../store/profile";
import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useDispatch } from "react-redux";

function Red() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);
  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <div>
      <Row className="rowo" justify="space-between">
        <Col lg={12} md={24} xs={24}>
          <div>
            <img className="bus img-fluid" src={Logo9999} alt="" />
          </div>
        </Col>
        <Col lg={9} md={24} xs={24}>
          <div className="divohol">
            <div className="divo">
              <h6 className="dd13">FREIGHTLINER DD15</h6>
            </div>
            <div className="potenhol">
              <div>
                <h6 className="poten">POTENCIA</h6>
              </div>
              <div className="power">
                <h6 className="poten">510 HP</h6>
              </div>
            </div>
            <div className="potenhol1">
              <div className="gray">
                <h6 className="carga">Capacidad de carga eje delantero</h6>
              </div>
              <div className="gray1">
                <h6 className="poten">14,600 LB</h6>
              </div>
            </div>
            <div className="potenhol">
              <div>
                <h6 className="poten">Capacidad de carga eje trasero</h6>
              </div>
              <div className="power">
                <h6 className="poten">46,000 LB</h6>
              </div>
            </div>
            <div className="potenhol1">
              <div className="gray">
                <h6 className="carga">UNIDADES DISPONIBLES</h6>
              </div>
              <div className="gray1">
                <h6 className="poten">2</h6>
              </div>
            </div>
            <div className="btonmng">
              <div>
                <button className="btonficha">
                  <h6 className="ficha">FICHA TECNICA</h6>
                </button>
              </div>
              <div>
                {loading ? (
                  <LoadingOutlined />
                ) : (
                  <>
                    {checkCartIfExist("FREIGHTLINER DD15") ? (
                      <p style={{ color: "green" }}>Added!</p>
                    ) : (
                      <button
                        className="btonficha1"
                        onClick={() => {
                          dispatch(
                            updateProductsState({
                              class: "Tractocamiones",
                              name: "FREIGHTLINER DD13",
                              potencia: "510 HP",
                              capacidad_de_carga_eje_delantero: "14,600 LB",
                              capacidad_de_carga_eje_traser: "46,000 LB",
                              unidades_disponibles: "3",
                            })
                          );

                          sethitUseEffect(!hitUseEffect);
                        }}
                      >
                        <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Red;

import React, { useState, useEffect } from "react";
import "./style.css";
import Logo99 from "../../image/Freightliner DD 13.jpg";
import { Col, Row } from "antd";
import NineteenSection from "../nineteenSection";
import TwentythreeSection from "../twentythreeSection";
import TwentyfourSection from "../twentyfourSection";
import Footer from "../FooterComponent";
import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import { useSelector } from "react-redux";
import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Red from "../redpart";
import TwentySix from "../twentySix";

function EleventhSection() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);
  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);

  return (
    <>
      <NavbarComponent />

      <div>
        <div>
          <div>
            <NineteenSection />
          </div>
        </div>
      </div>

      <div>
        <TwentythreeSection />
      </div>

      <Footer />
    </>
  );
}

export default EleventhSection;

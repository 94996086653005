import React, { useRef } from "react";
import "./style.css";
import { Col, Row, message } from "antd";
import { Input } from "antd";
import emailjs from "@emailjs/browser";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deleteAll } from "../../../store/profile";
import Footer from "../../FooterComponent";

const { TextArea } = Input;

function FourthSection() {
  const dispatch = useDispatch();

  const form = useRef();
  const { products } = useSelector((state) => state.profile);

  const beautifyText = (obj) => {
    let valueString = "";

    for (let i = 0; i < obj?.length; i++) {
      let text2 = `${obj[i]?.class}: ${obj[i]?.name}`;
      valueString = valueString + " " + " \n" + text2;
    }

    return valueString;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4jzn2lf",
        "template_9cmhnrr",
        form.current,
        "-zIWEcoT5oRZNwX_B"
      )
      .then(
        () => {
          dispatch(deleteAll());
          message.success("Email sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <div className="body3">
        <Row>
          <Col lg={24} md={24} xs={24}>
            <div className="maindiv21">
              <div>
                <h3 className="contact">COTIZAR</h3>
              </div>
              <form ref={form} onSubmit={sendEmail}>
                <div className="inputhol">
                  <div className="input-container">
                    <label className="input-label"></label>
                    <input
                      className="input-field00"
                      type="text"
                      name="NOMBRE"
                      placeholder="NOMBRE"
                      required={true}
                    />
                  </div>
                  <div className="holder">
                    <div className="input-container">
                      <label className="input-label"></label>
                      <input
                        className="input-field00"
                        type="email"
                        name="EMAIL"
                        placeholder="EMAIL"
                        required={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="inputhol1">
                  <div className="input-container">
                    <label className="input-label"></label>
                    <input
                      className="input-field00"
                      type="text"
                      name="TELEFONO"
                      placeholder="TELEFONO"
                      required={true}
                    />
                  </div>
                  <div className="input-container1">
                    <label className="input-label"></label>
                    <input
                      className="input-field00"
                      type="text"
                      name="EMPRISA"
                      placeholder="EMPRESA"
                      required={true}
                    />
                  </div>
                </div>

                <div className="inputhol1">
                  <div className="input-container">
                    <TextArea
                      className="text"
                      rows={4}
                      value={beautifyText(products)}
                    />
                    <input
                      style={{ display: "none" }}
                      type="text"
                      name="message"
                      value={beautifyText(products)}
                    />
                  </div>
                  <div className="input-container1">
                    <label className="input-label"></label>
                    <input
                      className="input-field00"
                      type="text"
                      name="UBICACIÓN"
                      placeholder="EQUIPOS"
                      required={true}
                    />
                  </div>
                </div>
                <div className="batan">
                  <button className="bton1">
                    <p className="evi">ENVIAR</p>
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
      <br />
      <br />
      <br />
    </>
  );
}

export default FourthSection;

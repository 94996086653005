import { Col, Row } from "antd";
import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import useWindowDimensions from "../getwidthHeight";
import NavbarComponent from "../NavbarComponent";
import Footer from "../FooterComponent";
import TwentyFive from "../twentyFive";
function NinthSection() {
  const { width } = useWindowDimensions();

  return (
    <>
      <NavbarComponent />
      <div className="bodytwo">
        <div>
          <Row>
            <Col lg={24} md={24} xs={24}>
              <div className="maqhol">
                <h2 className="maq">Maquinaria</h2>
              </div>
            </Col>
          </Row>
          <Row
            style={{ marginLeft: width < 450 ? "0rem" : "3rem" }}
            justify="center"
          >
            <Col className="centro" lg={6} md={10} xs={24}>
              <div className="containerhol">
                <Link to="/Excavadora">
                  <div className="image-container0"></div>
                </Link>
                <h4 className="excava">EXCAVADORAS</h4>
              </div>
            </Col>
            <Col className="centro" lg={6} md={10} xs={24}>
              <Link to="/retroexcavadors">
                <div className="image-container1"></div>
              </Link>

              <h4 className="excava">RETROEXCAVADORAS</h4>
            </Col>
            <Col className="centro" lg={6} md={10} xs={24}>
              <Link to="/MOTOCONFORMADORAS">
                <div className="image-container2"></div>
              </Link>
              <h4 className="excava">MOTOCONFORMADORAS</h4>
            </Col>
            <Col className="centro" lg={6} md={10} xs={24}>
              <Link to="/pavimentadoras">
                <div className="image-container22"></div>
              </Link>
              <h4 className="excava">TREN DE PAVIMENTACIÓN</h4>
            </Col>
            <Col className="centro" lg={6} md={10} xs={24}>
              <Link to="/VIBROCOMPACTADORES">
                <div className="image-container3"></div>
              </Link>
              <h4 className="excava">VIBROCOMPACTADORES</h4>
            </Col>
            <Col className="centro" lg={6} md={10} xs={24}>
              <Link to="/TRACTORES-BULLDOZERS">
                <div className="image-container4"></div>
              </Link>
              <h4 className="excava">TRACTORES BULLDOZERS</h4>
            </Col>
            <Col className="centro" lg={6} md={10} xs={24}>
              <Link to="/cragdor">
                <div className="image-container5"></div>
              </Link>

              <h4 className="excava">CARGADOR FRONTAL</h4>
            </Col>
          </Row>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default NinthSection;

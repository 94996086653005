import React, { useState, useEffect } from "react";
import "./style.css";
import { Col, Row } from "antd";
import NavbarComponent from "../NavbarComponent";
import Footer from "../FooterComponent";

import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import { useSelector } from "react-redux";

import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";

function SeventhSection() {

  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />
      <div>
        <Row justify="center" className="hills">
          <Col lg={7} md={24} xs={24}>
            <div className="ventahol">
              <h5 className="venta">VENTA AGREGADOS PÉTREOS</h5>
              <p className="con">
                Somos una empresa proveedora de agregados pétreos. enfocados a
                solucionar con eficiencia las necesidades de tu provecto
              </p>
              <p className="con">
                Ponemos a tu alcance una amplia gama de materiales de calidad y
                contamos con la infraestructura para el suministro de la misma.
              </p>
            </div>
            <div className="coti-hol">
                  {loading ? (
                    <LoadingOutlined />
                  ) : (
                    <>
                      {checkCartIfExist("AGREGADOS PÉTREOS") ? (
                        <p style={{ color: "green" }}>Added!</p>
                      ) : (
                        <button
                        className="bton-coti"
                          onClick={() => {
                            dispatch(
                              updateProductsState({
                                class: "Venta",
                                name: "AGREGADOS PÉTREOS",
                              })
                            );

                            setloading(true);
                            sethitUseEffect(!hitUseEffect);
                          }}
                        >
                          <h6 className="teni">COTIZAR</h6>
                        </button>
                      )}
                    </>
                  )}
                </div>
          </Col>
          <Col style={{ height: "70vh" }} lg={17} md={24} xs={24}>
            <div className="hope">
              <div className="bluebarhol">
                <p className="textbluehol">
                  Permítenos eficientar tus proyectos poniendo a<br />
                  tu disposición nuestra asesoria personalizada.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default SeventhSection;

import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col } from "antd";
import LogoGD from "../../image/Motoconformadora Komatsu GD555-5 (1).jpg";
import LogoGO from "../../image/Motoconformadora Caterpillar 140M (1).png";
import Logo65 from "../../image/Motoconformadora Komatsu GD675-5.jpg";
import Logo140 from "../../image/Motoconformadora Caterpillar 140 K.jpg";
import Logo14 from "../../image/devlon.jpg";

import Footer from "../FooterComponent";
import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import { useSelector } from "react-redux";

import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";

function TwentytwoSection() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />
      <div>
        <div className="border">
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={LogoGD} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KOMATSU GD555-5</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">193 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO CUCHILLA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">4.3 M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">17,065 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">4</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Motoconformadoras/Komatsu GD555.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KOMATSU GD555-5") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Motoconformadoras",
                                  name: "KOMATSU GD555-5",
                                  potencia: "193 HP",
                                  ancho_cuchilla: "4.3 M",
                                  peso_operativo: "17,065 KG",
                                  unidades_disponibles: "2",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={LogoGO} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">CATERPILLAR 140M</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">192 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO CUCHILLA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">4.3 M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">18,991 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Motoconformadoras/Caterpillar 140m.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KOMATSU D65PX- 17") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Motoconformadoras",
                                  name: "KOMATSU D65PX- 17",
                                  potencia: "192 HP",
                                  ancho_cuchilla: "4.3 M",
                                  peso_operativo: "18,991 KG",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo2" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo65} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KOMATSU GD675-5</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">221 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO CUCHILLA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">4.3 M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">19,355 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Motoconformadoras/Komatsu GD675-5.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KOMATSU GD675-5") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Motoconformadoras",
                                  name: "KOMATSU GD675-5",
                                  potencia: "221 HP",
                                  ancho_cuchilla: "4.3 M",
                                  peso_operativo: "19,355 KG",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="rowo2" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo140} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">CATERPILLAR 140 K</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">221 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO CUCHILLA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">4.3 M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">14,768 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">5</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/Motoconformadoras/Caterpillar140K.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("CATERPILLAR 140 K") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Motoconformadoras",
                                  name: "CATERPILLAR 140 K",
                                  potencia: "221 HP",
                                  ancho_cuchilla: "4.3 M",
                                  peso_operativo: "14,768 KG",
                                  unidades_disponibles: "3",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo2" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo14} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">DEVELON DG 190</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">190 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO CUCHILLA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">4.3 M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">15,600 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <a
                      href="/pdf/HDI_DG130_DG190_DG220_EN_low.pdf"
                      target="_blank"
                    >
                      <button className="btonficha">
                        <h6 className="ficha">FICHA TECNICA</h6>
                      </button>
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("DEVELON DG 190") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Motoconformadoras",
                                  name: "DEVELON DG 190",
                                  potencia: "221 HP",
                                  ancho_cuchilla: "4.3 M",
                                  peso_operativo: "14,768 KG",
                                  unidades_disponibles: "3",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TwentytwoSection;

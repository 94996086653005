import React from "react";
import "./style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Cards from "./cards";

function HeroSection(props) {
  const width = props?.dimensions?.width;

  return (
    <div style={{ padding: width < 450 ? "0 0rem 0 0rem" : "0 4rem 0 4rem" }}>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={4000}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
      >
        <div>
          <img src="/imgs/c1.jpg" />
        </div>
        <div>
          <img src="/imgs/c2.jpg" />
        </div>
        <div>
          <img src="/imgs/c3.jpg" />
        </div>
      </Carousel>

      <Cards />
    </div>
  );
}

export default HeroSection;

import { createSlice } from "@reduxjs/toolkit";

export const ProfileSlice = createSlice({
  name: "profile",
  initialState: {
    isLoogedIn: false,
    products: [],
  },
  reducers: {
    updateLoginState: (state, action) => {
      state.isLoogedIn = action.payload;
    },
    updateProductsState: (state, action) => {
      state.products = [...state.products, action.payload];
    },
    excludeProductsState: (state, action) => {
      let data = [...action.payload.products];

      for (var i = 0; i < data?.length; i++) {
        if (data[i]?.name === action.payload.name) {
          data.splice(i, 1);
          break;
        }
      }
      state.products = data;
    },
    deleteAll: (state) => {
      state.products = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateLoginState,
  updateProductsState,
  excludeProductsState,
  deleteAll,
} = ProfileSlice.actions;

export default ProfileSlice.reducer;

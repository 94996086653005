import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col } from "antd";
import Logo11 from "../../image/Tractor Bulldozer Komatsu D65PX.png";
import Logo1122 from "../../image/Tractor Bulldozer Komatsu D155.jpg";

import Logo1313 from "../../image/Tractor Bulldozer Caterpillar D6T.png";
import Footer from "../FooterComponent";
import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import { useSelector } from "react-redux";

import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";

function FourteenSection() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };
  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />
      <div>
        <div className="border">
          <Row className="rowo" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo1313} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">CATERPILLAR D6T</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">185 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO CUCHILLA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">3.26 M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">19,969 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("CATERPILLAR D6T") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Tractores Bulldozers",
                                  name: "CATERPILLAR D6T",
                                  potencia: "185 HP",
                                  ancho_cuchilla: "3.26 M",
                                  peso_operativo: "19,969 KG",
                                  unidades_disponibles: "2",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo1" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo11} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KOMATSU D65PX- 17</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">207 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO CUCHILLA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">3.97M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">19,380 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">1</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KOMATSU D65PX- 17") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Tractores Bulldozers",
                                  name: "KOMATSU D65PX- 17",
                                  potencia: "207 HP",
                                  ancho_cuchilla: "3.97M",
                                  peso_operativo: "19,380 KG",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="rowo2" justify="space-between">
            <Col lg={12} md={24} xs={24}>
              <div>
                <img className="bus img-fluid" src={Logo1122} alt="" />
              </div>
            </Col>
            <Col lg={9} md={24} xs={24}>
              <div className="divohol">
                <div className="divo">
                  <h6 className="dd13">KOMATSU D155</h6>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">POTENCIA</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">359 HP</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">ANCHO CUCHILLA</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">4.06M</h6>
                  </div>
                </div>
                <div className="potenhol">
                  <div>
                    <h6 className="poten">PESO OPERATIVO</h6>
                  </div>
                  <div className="power">
                    <h6 className="poten">39,500 KG</h6>
                  </div>
                </div>
                <div className="potenhol1">
                  <div className="gray">
                    <h6 className="carga">UNIDADES DISPONIBLES</h6>
                  </div>
                  <div className="gray1">
                    <h6 className="poten">2</h6>
                  </div>
                </div>
                <div className="btonmng">
                  <div>
                    <button className="btonficha">
                      <h6 className="ficha">FICHA TECNICA</h6>
                    </button>
                  </div>
                  <div>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <>
                        {checkCartIfExist("KOMATSU D155") ? (
                          <p style={{ color: "green" }}>Added!</p>
                        ) : (
                          <button
                            className="btonficha1"
                            onClick={() => {
                              dispatch(
                                updateProductsState({
                                  class: "Tractores Bulldozers",
                                  name: "KOMATSU D155",
                                  potencia: "359 HP",
                                  ancho_cuchilla: "4.06M",
                                  peso_operativo: "39,500 KG",
                                  unidades_disponibles: "1",
                                })
                              );

                              setloading(true);
                              sethitUseEffect(!hitUseEffect);
                            }}
                          >
                            <h6 className="ficha">AÑADIR A COTIZACIÓN</h6>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FourteenSection;

import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col } from "antd";

import Footer from "../FooterComponent";
import { useDispatch } from "react-redux";
import { updateProductsState } from "../../store/profile";
import NavbarComponent from "../NavbarComponent";
import { useSelector } from "react-redux";

import { checkCartIfExist } from "../../helper";
import { LoadingOutlined } from "@ant-design/icons";
import { Container } from "react-bootstrap";

function ResiduosPeligrosos() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.profile);

  const [loading, setloading] = useState(false);
  const [hitUseEffect, sethitUseEffect] = useState(false);

  const handleLoading = () => {
    setloading(false);
  };
  useEffect(() => {
    setloading(true);

    setTimeout(handleLoading, 1000);
  }, [hitUseEffect, products]);
  return (
    <>
      <NavbarComponent />

      <div className="backgroundImageofoildrig">
        <h2 className="headingclassofoilrig">
          Recolección y Transporte de Residuos Peligrosos Residuos de Manejo
          Especial
        </h2>

        <div className="backgroundofoilrigcolors">
          <h4 className="headinginsidetextcontainerolirig">
            GRUPO JL es una empresa que se suma al mercado con servicios
            integrales al sector de hidrocarburos.
          </h4>
          <h4 className="headinginsidetextcontainerolirig">
            Ofrecemos un servicio especializado en el transporte seguro y
            responsable de residuos peligrosos y de manejo especial. Nuestra
            prioridad es garantizar la protección del medio ambiente y la
            seguridad en cada fase del proceso. Confíe en nuestro equipo experto
            para manejar sus residuos con el máximo cuidado y cumplimiento de
            todas las normativas vigentes. Juntos, podemos contribuir a un
            entorno más seguro y sostenible.
          </h4>
        </div>
        <div className="coti-holoilrig">
          {loading ? (
            <LoadingOutlined />
          ) : (
            <>
              {checkCartIfExist("Recolección y Transporte") ? (
                <p style={{ color: "green" }}>Added!</p>
              ) : (
                <button
                  className="bton-coti"
                  onClick={() => {
                    dispatch(
                      updateProductsState({
                        class: "Venta",
                        name: "Recolección y Transporte",
                      })
                    );

                    setloading(true);
                    sethitUseEffect(!hitUseEffect);
                  }}
                >
                  <h6 className="teni">COTIZAR</h6>
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <Container style={{ marginTop: "5rem" }}>
        <Row justify={"space-between"}>
          <Col lg={7} md={7} sm={7} xs={7}>
            <img
              style={{ width: "100%" }}
              src="https://news.dna3.com.mx/wp-content/uploads/2020/10/comunicaciones.png"
            />
          </Col>
          <Col style={{ marginTop: "2rem" }} lg={7} md={7} sm={7} xs={7}>
            <img
              style={{ width: "100%" }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUgl6ZVJEZVIZyMYupYimreCp8Y2Y4qZzTKw&s"
            />
          </Col>
          <Col lg={7} md={7} sm={7} xs={7}>
            <img
              style={{ width: "80%" }}
              src="https://www.gob.mx/cms/uploads/article/main_image/16073/logo_asea.jpg"
            />
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default ResiduosPeligrosos;
